import Map from "../assets/images/nmap.png"
import Quotes from "../assets/images/quotes.svg"
export default function LocationMap() {
    return (<div className="background4">
        <div className="max-w-[100%] mx-auto  pt-24 pb-24 px-4 lg:px-0">
            <div className="flex flex-col justify-center items-center">
                <div className="nav-text-color text-[56px] leading-[50px] font-bold text-center">Location we <span className="theme-text-color cover_font text-[56px] leading-[56px] font-normal">currently deliver</span></div>
                <div className="mt-[30px]  relative">
                    <div className="lg:w-[642px] lg:h-[676px]">
                        <img src={Map} className="w-full h-full object-cover"></img>
                    </div>

                    {/* <div className="absolute bg-[#fff] map-phrase top-[10%] xl:right-[-50%] p-2 lg:p-7">
                        <div><img src={Quotes} /></div>
                        <div className="text-[12px]  lg:text-[18px] lg:max-w-full mt-[10px] lg:leading-[32px] text-[#5D6473]">I really like the team for example, a
                            relationship-focused performance expectation
                            might be that the employee sustain collegial</div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>)
}