import BorderBackground from "../assets/images/ziczac.svg";

import DmanLogo from "../assets/images/dmanlogo.svg";
import Dman from "../assets/images/dman.svg";

import { useNavigate, useLocation } from "react-router-dom";

import { useEffect, useState } from "react";
export let navlist = [
  { name: "Home", isActive: false, id: "home" },
  { name: "Our services", active: false, id: "services" },
  // { name: "Vision", active: false },
  { name: "Our team", active: false, id: "teams" },
  { name: "Our investors", active: false, id: "investors" },
  // { name: "About us", active: false ,id:"teams" },
];
export default function Header() {
  const [navList, setNavList] = useState([...navlist]);
  const [openNav, setOpenNav] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const routername = pathName === "/terms" || pathName === "/privacy-policy";


  const handleNavChange = (i, item) => {
    navList.forEach((el, ind) => {
      navlist[ind].isActive = false;
    });
    navList[i].isActive = true;
    if (item.id) {
      window.location.hash =  "#/" + item.id;
    }
    setTimeout(() => {
      setNavList([...navList]);
      item.id && scrollToElement(item.id);
    }, 1)
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setBackgroundColor(true);
    } else {
      setBackgroundColor(false);
    }
  };
  let path = location.pathname.split('/')[1];
  let module = ["home", "services", "teams","investors"];
  //   window.addEventListener("scroll", handleScroll);
  useEffect(() => {
    if (path === "" && location.hash) path = location.hash.split('/')[1];
    navList.some((route, i) => {
      let found = false
      if (route.id === path || (path === "" && route.id === 'home')) {
        handleNavChange(i, route)
        found = true
      }
      return found
    })
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ top: "0", behavior: "smooth" });
    }
  };
  return (
    <div className="background1 header">
      {/* header 1 */}
      <div className="w-full h-[56px] sticky top-0 z-10">
        <div className="w-full h-full relative">
          <img
            src={BorderBackground}
            className="w-full h-full object-cover absolute top-0 left-0"
            alt=""
          ></img>
          <p className="absolute top-0 w-full h-full flex items-center justify-center">
            <div className="text-[#fff] leading-4 text-[14px] md:text-[16px] text-center font-semibold">
              Thanks to all incredible customers and supporters. We have
              delivered 1,00,000+ Orders in Sivaganga via Calls
            </div>
          </p>
        </div>
        <div
          className={`relative sticky top-20 z-10 px-[55px] py-4 ${
            backgroundColor && "change-navbar-style"
          }`}
        >
          {/* <div className="absolute top-0 "><img src={circledesign}></img></div> */}
          <div className="flex justify-between items-center relative">
            <div className="flex items-center gap-3 cursor-pointer" onClick={() => handleNavChange(0, navlist[0])}>
              <div>
                <img src={DmanLogo} className="h-[50px]"></img>
              </div>
              <div>
                <img src={Dman} className="h-[20px]"></img>
                <p className="text-[12px] font-medium  mt-[8px] nav-text-color">
                  Delivering Happiness
                </p>
              </div>
            </div>
            <div className=" hidden md:block ">
              <ul className="flex flex-col md:flex-row gap-10 nav_list_wrapper mr-[150px]">
                {navList.map((el, i) => (
                  <li
                    className={
                      "nav_each_list" +
                      " " +
                      `${
                        module.includes(path) && el.isActive ? "active" : ""
                      }`
                    }
                    onClick={() => handleNavChange(i, el)}
                  >
                    {el.name}
                  </li>
                ))}
              </ul>
            </div>

            <div className="block md:hidden mr-2">
              <button
                className={
                  openNav
                    ? "navbar-toggler navbar-toggle-show"
                    : "navbar-toggler"
                }
                type="button"
                onClick={() => setOpenNav(!openNav)}
              >
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
            {openNav && (
              <ul className="flex w-full flex-col items-center background1 md:hidden block md:flex-row gap-5 nav_list_wrapper absolute top-[100%]">
                {navList.map((el, i) => (
                  <li
                    className={
                      "nav_each_list" + " " + `${el.isActive ? "active" : ""}`
                    }
                    onClick={() => {
                      handleNavChange(i, el);
                      setOpenNav(false);
                    }}
                  >
                    {el.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      {/* header 2*/}
    </div>
  );
}
