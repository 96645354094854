import gros from "../assets/images/groceries.svg"
import veg from "../assets/images/veg.svg"
import cake from "../assets/images/cake.svg"
import fish from "../assets/images/fish.svg"
import medicine from "../assets/images/medicine.svg"
import pickup from "../assets/images/pick.svg"
import store from "../assets/images/store.svg"
import foods from "../assets/images/foods.svg"
export default function Services() {
    return (<div className="bg-[#fff]">
        <div className="max-w-[100%] mx-auto pt-24 pb-36 lg:px-16">
            <div className="background5 rounded-[20px]  p-4 lg:p-14">
                <div className="text-[56px] font-bold leading-[56px] text-center">Our <span className="theme-text-color font-normal text-[56px] leading-[56px] cover_font">services</span></div>
                {/* <div className="mt-[100px]">
                    <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row items-center">
                        <div className="lg:w-1/2">
                            <div className="title-text-color  text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Grocery</div>
                            <div className="lg:w-3/4 text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">One place for Groceries, Medicines, Food and much more. Find everything you need in one app! Explore and shop for the best quality home and personal essentials.</div>
                        </div>
                        <div className="lg:w-[416px] lg:h-[501px] lg:ml-auto" onContextMenu={(e) => e.preventDefault()}>
                            <img src={Image} className="w-full h-full object-cover" ></img>
                        </div>
                    </div>
                </div>
                <div className="mt-[120px]">
                    <div className="flex flex-col flex-col-reverse lg:flex-row gap-3 lg:gap-0 lg:flex-row  items-center">
                        <div className="lg:w-[416px] lg:h-[501px]" onContextMenu={(e) => e.preventDefault()}>
                            <img src={Image} className="w-full h-full object-cover"></img>
                        </div>
                        <div className="lg:w-1/2 lg:ml-auto">
                            <div className="title-text-color  text-center lg:text-left  text-[50px] font-bold leading-[50px] tracking-[-2px]">Grocery & Essentials</div>
                            <div className="lg:w-[78%] text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Explore the widest range of products. Checkout and choose from a variety of products available from stores across the city.</div>
                        </div>

                    </div>
                </div>

                <div className="mt-[120px]">
                    <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row items-center">
                        <div className="lg:w-1/2">
                            <div className="title-text-color text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Stores</div>
                            <div className="lg:w-3/4 text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Shop from local trusted stores around you. From fresh veggies to fashion, order from local stores you trust.</div>
                        </div>
                        <div className="lg:w-[416px] lg:h-[501px] lg:ml-auto">
                            <img src={Image} className="w-full h-full object-cover" onContextMenu={(e) => e.preventDefault()}></img>
                        </div>
                    </div>
                </div>

                <div className="mt-[120px]">
                    <div className="flex flex-col flex-col-reverse lg:flex-row gap-3 lg:gap-0 lg:flex-row items-center">
                        <div className="lg:w-[416px] lg:h-[501px]">
                            <img src={Image} className="w-full h-full object-cover" onContextMenu={(e) => e.preventDefault()}></img>
                        </div>
                        <div className="lg:w-1/2 lg:ml-auto">
                            <div className="title-text-color text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Payment</div>
                            <div className="lg:w-5/6 text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Safe and Secure payments powered by Gpay, PhonePe and IppoPay. Get the same convenience, trust and security you get on every time you shop</div>
                        </div>
                    </div>
                </div> */}
               <div className="mt-[100px]">
                    <div className="flex flex-col flex-col-reverse gap-3 lg:gap-0 lg:flex-row items-center">
                        <div className="lg:w-[60%]">
                            <div className="title-text-color  text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Foods 🍕</div>
                            <div className="lg:w-[80%] text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Order your favorite dishes from nearby hotels or restaurants using the D-man delivery app, or just give us a call on our toll-free number to place your order from various restaurants.</div>
                        </div>
                        <div className="lg:w-[501px] lg:h-[370px]" onContextMenu={(e) => e.preventDefault()}>
                            <img src={foods} className="w-full h-full " ></img>
                        </div>
                    </div>
                </div>
                <div className="mt-[100px]">
                    <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row items-center">
                    <div className="lg:w-[501px] lg:h-[370px]" onContextMenu={(e) => e.preventDefault()}>
                            <img src={gros} className="w-full h-full " ></img>
                        </div>
                        <div className="lg:ml-[60px] lg:w-[60%]">
                            <div className="title-text-color  text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Groceries 🛒</div>
                            <div className="lg:w-[100%] text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Get all your daily essentials delivered to your doorstep. From fresh produce to pantry staples, we ensure quality and freshness for all your grocery needs.</div>
                        </div>
                        
                    </div>
                </div>
                <div className="mt-[100px]">
                    <div className="flex flex-col flex-col-reverse lg:flex-row gap-3 lg:gap-0 lg:flex-row  items-center">
                       
                        <div className="lg:w-[60%]">
                            <div className="title-text-color  text-center lg:text-left  text-[50px] font-bold leading-[50px] tracking-[-2px]">Medicines 💊</div>
                            <div className="lg:w-[80%] text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Need medications urgently? We deliver prescription and over-the-counter medicines quickly and reliably. Your health is our priority.</div>
                        </div>
                        <div className="lg:w-[448px] lg:h-[370px]" onContextMenu={(e) => e.preventDefault()}>
                            <img src={medicine} className="w-full h-full"></img>
                        </div>

                    </div>
                </div>

                <div className="mt-[100px]">
                    <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row items-center">
                    <div className="lg:w-[442px] lg:h-[400px] lg:ml-auto">
                            <img src={veg} className="w-full h-full " onContextMenu={(e) => e.preventDefault()}></img>
                        </div>
                        <div className="lg:ml-[60px] lg:w-[60%]">
                            <div className="title-text-color text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Vegetables 🥦</div>
                            <div className="lg:w-[100%] text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Enjoy farm-fresh vegetables handpicked for quality. Stay healthy with the freshest greens delivered directly to your home.</div>
                        </div>
                       
                    </div>
                </div>

                <div className="mt-[100px]">
                    <div className="flex flex-col flex-col-reverse lg:flex-row gap-3 lg:gap-0 lg:flex-row items-center">
                        
                        <div className="lg:w-[60%]">
                            <div className="title-text-color text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Fish & Meats 🐟🍖</div>
                            <div className="lg:w-[80%]  text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Order premium quality, hygienically processed fish and meats. We bring the best cuts, ensuring freshness and safety in every bite.</div>
                        </div>
                        <div className="lg:w-[490px] lg:h-[400px]">
                            <img src={fish} className="w-full h-full" onContextMenu={(e) => e.preventDefault()}></img>
                        </div>
                    </div>
                </div>
                <div className="mt-[100px]">
                    <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row items-center">
                    <div className="lg:w-[576px] lg:h-[370px] lg:ml-auto">
                            <img src={cake} className="w-full h-full" onContextMenu={(e) => e.preventDefault()}></img>
                        </div>
                        <div className="lg:ml-[60px] lg:w-[60%]">
                            <div className="title-text-color text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Cakes 🎂</div>
                            <div className="lg:w-[100%] text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Celebrating something special? Get custom and ready-made cakes from your favorite bakeries delivered right to your party, fresh and on time.</div>
                        </div>
                       
                    </div>
                </div>

                <div className="mt-[100px]">
                    <div className="flex flex-col flex-col-reverse lg:flex-row gap-3 lg:gap-0 lg:flex-row items-center">
                        
                        <div className="lg:w-[60%]">
                            <div className="title-text-color text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Pickup & Drop Service 📦</div>
                            <div className="lg:w-[80%] text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Forgot something at a friend's place or need to send a package? We provide fast and reliable pickup and drop services to make your life easier.</div>
                        </div>
                        <div className="lg:w-[410px] lg:h-[410px]">
                            <img src={pickup} className="w-full h-full object-cover" onContextMenu={(e) => e.preventDefault()}></img>
                        </div>
                    </div>
                </div>
                <div className="mt-[100px]">
                    <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row items-center">
                    <div className="lg:w-[573px] lg:h-[370px] lg:ml-auto ">
                            <img src={store} className="w-full h-full" onContextMenu={(e) => e.preventDefault()}></img>
                        </div>
                        <div className="lg:ml-[60px] lg:w-[60%]">
                            <div className="title-text-color text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Multi-Store Orders 🌟</div>
                            <div className="lg:w-[100%] text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Why stick to one store? With D Man, you can order from multiple stores in a single delivery! Combine groceries, medicines, and more, and we’ll bring it all to you in one go.</div>
                        </div>
                        
                    </div>
                </div>
                </div>
            
        </div>
    </div>)
}