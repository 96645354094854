import { Link, useNavigate, useLocation } from "react-router-dom";
import DmanLogo from "../assets/images/Layer_1.svg";
import Dman from "../assets/images/dman.svg";
import Facebook from "../assets/images/facebook.svg";
import Twitter from "../assets/images/twitter.svg";
import Instagram from "../assets/images/instagram.svg";
import {navlist} from "./Header"

let listcls =
  "text-[#333333] text-[16px] leading-[18px] tracking-[0.32px] cursor-pointer";

export default function Footer() {
  const scrollToElement = (elementId) => {
    window.location.hash = '/' + elementId;
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ top: "0", behavior: "smooth" });
      }
    }, 1)
  };


  return (
    <div className="background6">
      <div className="max-w-[100%] mx-auto px-10 lg:px-14 py-14">
        <div className="flex gap-20 lg:flex-row flex-col justify-between items-center">
          <div className="">
            <div
              className="flex items-center gap-3 cursor-pointer"
              onClick={() => scrollToElement("home")}
            >
              <div>
                <img src={DmanLogo} className="h-[50px]"></img>
              </div>
              <div>
                <img src={Dman} className="h-[20px]"></img>
                <p className="text-[12px] font-medium  mt-[8px] text-[#333333]">
                  Delivering Happiness
                </p>
              </div>
            </div>
          </div>
          <div className="flex gap-10 flex-wrap justify-center">
            {navlist.map((nav, i) => (
              <div className={listcls} onClick={() => scrollToElement(nav.id)}>{nav.name}</div>
            ))}
          </div>
        </div>
        <div className="mt-10 mb-10 h-[1px] bg-[#DDDDDD]"></div>
        <div className="flex flex-col lg:flex-row justify-between items-center gap-2">
          <div className="text-[18px] leading-[18px] tracking-[0.32px] text-[#333333]">
            © {new Date().getFullYear()} D-Man Pvt. Ltd
          </div>
          <div className="text-[#333333] flex gap-10">
            <div
              className="text-[#333333] text-[16px] leading-[18px] cursor-pointer"
              onClick={() => scrollToElement("terms")}
            >
              Terms & conditions
            </div>
            <div
              className="text-[#333333] text-[16px] leading-[18px] cursor-pointer"
              onClick={() => scrollToElement("privacy-policy")}
            >
              Privacy policy
            </div>
            <div className="flex gap-4">
              <a href="https://www.facebook.com/share/181BawF8yC/?mibextid=LQQJ4d" target="_blank">
                <img src={Facebook} className="w-full h-full"></img>
              </a>
              {/* <a href="" target="_blank">
                <img src={Twitter} className="w-full h-full"></img>
              </a> */}
              <a href="https://www.instagram.com/dman.in/profilecard/?igsh=eHlraWc2dGoxNjY4" target="_blank">
                <img src={Instagram} className="w-full h-full"></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
