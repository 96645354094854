import jai from "../assets/images/jai.svg";
import mohan from "../assets/images/Mohan.svg";
import karthi from "../assets/images/karthi.svg";
import In from "../assets/images/in.svg";
import ScrollToTop from "./scrollTop";

let advisorList = [
  {
    img: mohan,
    name: "Mohan",
    job: "Co-Founder & CEO at IppoPay",
    linked: "https://www.linkedin.com/in/itmohans/",
  },
  {
    img: jai,
    name: "Jai Kumar",
    job: "Co-Founder & CTO at IppoPay",
    linked: "https://www.linkedin.com/in/jaikumaraxn/",
  },
  {
    img: karthi,
    name: "Karthick Sivaram",
    job: "Co-Founder & COO at Backspace",
    linked: "https://www.linkedin.com/in/karthick-sivaram/",
  },
];

const Investors = () => {
  return (
    <>
      <div className="mt-[140px] nav-text-color text-[46px] lg:text-[56px] leading-[46px] lg:leading-[56px] font-bold text-center pt-5 pb-16">
        Our Visionary{" "}
        <span className="theme-text-color cover_font text-[56px] leading-[56px] font-normal">
          Investors{" "}
          <span className="text-[#2E2F35] font-bold text-[56px] leading-[56px]">
            &
          </span>{" "}
          Advisory
        </span>{" "}
        Team
      </div>
      <div className="flex gap-10 lg:gap-16 min-h-[300px] flex-col items-center lg:justify-center lg:flex-row lg:items-end flex-wrap  mb-[74px]">
        {advisorList.map((advisor) => (
          <div className="team-card">
            <div className="p-[10px]">
              <div className=" h-[160px]">
                <img
                  src={advisor.img}
                  className="w-inherit h-inherit object-cover main "
                />
                <a href={advisor.linked} target="_blank">
                  <img
                    src={In}
                    className="w-inherit h-inherit object-cover linked ease-in duration-300"
                  />
                </a>
              </div>
            </div>
            <div className="relative min-w-[100%] lg:min-w-[336px] max-w-[336px] mt-[80px] flex flex-col justify-end items-center p-6 ">
              <div className="flex items-center gap-5">
                <div className="text-[#0D2159] text-[20px] leading-[20px] font-semibold">
                  {advisor.name ?? ""}
                </div>
              </div>

              <div className="text-[#6D84A6] text-[16px] leading-[16px] mt-[10px]">
                {advisor.job ?? ""}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default Investors;
