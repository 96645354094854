import { Outlet } from "react-router-dom";
import Footer from "./footer";
import Header from "./Header";
export default function Main() {
  
  return (
    <>
      <div>
        <Header />
      </div>
      <Outlet />
      <div>
        <Footer />
      </div>
    </>
  );
}
