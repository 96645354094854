import { useEffect, useState } from "react";
import DownArrow from "../assets/images/download.png";
import Home from "../assets/images/home2.png";
import { useNavigate } from "react-router-dom";
let transTimeout;
clearTimeout(transTimeout);
export default function Navbar() {
  const navigate = useNavigate();

  return (
    <div className="background1">
      <div className="max-w-[100%] mx-auto lg:pl-[59px] px-6 md:pr-0 pt-[80px] lg:pb-0 pb-10 bg-set">
        <div className="flex justify-between w-[100%] mt-[50px] md:mt-0 lg:items-center flex-col lg:flex-row">
          <div className="lg:w-1/2 banner-text">
            <p className="title-text-color text-[40px] lg:text-[50px] font-bold food_slide pr-[99px]">
              Sivaganga’s most Trusted Delivery service to order
              {/* {list &&
                list.map((item, i) => (
                  <span
                    key={i}
                    className={`block lg:inline lg:ml-[10px] ${count && "b1"} cover_font text-[66px] leading-[77px] font-normal theme-text-color`}
                  >
                    
                    {Number(count) === Number(i) && item}
                  </span>
                ))} */}
              <span className="block lg:inline lg:ml-[10px] box b1  cover_font text-[66px] leading-[77px] font-normal theme-text-color  ">
                {" "}
                Foods
              </span>
              <span className="block lg:inline lg:ml-[10px] box b2  cover_font text-[50px] lg:text-[66px] leading-[77px] font-normal theme-text-color  ">
                {" "}
                Vegetables
              </span>
              <span className="block lg:inline lg:ml-[10px] box b3  cover_font text-[50px] lg:text-[66px] leading-[77px] font-normal theme-text-color  ">
                {" "}
                Groceries
              </span>
              <span className="block lg:inline lg:ml-[10px] box b4  cover_font text-[50px] lg:text-[66px] leading-[77px] font-normal theme-text-color  ">
                {" "}
                Medicines
              </span>
              <span className="block lg:inline lg:ml-[10px] box b5  cover_font text-[50px] lg:text-[66px] leading-[77px] font-normal theme-text-color">
                {" "}
                Meats
              </span>
              <span className="block lg:inline lg:ml-[10px] box b6  cover_font text-[50px] lg:text-[66px] leading-[77px] font-normal theme-text-color">
                {" "}
                Seafoods
              </span>
            </p>
            <p className="default-text-color mt-[130px] lg:mt-[35px] font-medium text-[18px] leading-[32px] ">
              "Download D-Man - Bringing Your Home Products Straight to Your
              Doorstep!"
            </p>
            <button className="mt-[20px] m-auto lg:mx-0 relative w-[215px] h-[60px] bg-[#434343] flex justify-center items-center gap-3 rounded download-btn">
              <div className="w-[24px] h-[24px]">
                <img src={DownArrow} className="image-view" />
              </div>
              <div
                className="text-[#fff] tracking-[0.32px] font-bold "
                onClick={() => navigate("/download")}
              >
                Download Now
              </div>
            </button>
          </div>
          <div className="lg:w-1/2 xl:w-[755px] xl:h-[746] ml-auto">
            <img src={Home} className="w-full h-full object-cover"></img>
          </div>
        </div>
        {/* customers count */}
        <div className="mr-0">
          <div className="flex flex-col justify-center items-center">
            <div className="title-text-color text-[40px] lg:text-[54px]  nav-text-color font-bold ">
              Some{" "}
              <span className="cover_font font-normal theme-text-color leading-[54px] text-[54px]">
                number
              </span>{" "}
              that matter
            </div>
            <div className="default-text-color font-medium text-[18px] lg:leading-[18px] mt-[12px]">
              In Sivaganga, we have taken the orders via calls and whatsapp
              messaging
            </div>
          </div>
          <div className="flex flex-col gap-3 lg:flex-row justify-between mt-[110px]  lg:pr-[59px]">
            <div className="reviews_wrapper w-full min-h-[200px] lg:w-[390px] lg:h-[252px]">
              <div className="review_title">165K+</div>
              <div className="review_desc">Orders Delivered</div>
            </div>
            <div className="reviews_wrapper w-full min-h-[200px] lg:w-[390px] lg:h-[252px]">
              <div className="review_title">15K+</div>
              <div className="review_desc">Customers</div>
            </div>
            <div className="reviews_wrapper w-full min-h-[200px] lg:w-[390px] lg:h-[252px]">
              <div className="review_title">100+</div>
              <div className="review_desc">Store Partners</div>
            </div>
            {/* <div className="flex  gap-3 lg:gap-8">
                            <div className="w-[128px] h-[128px] flex_jc_ac bg-[#c06ef330] rounded-[40px]"><img src={Icon1}></img></div>
                            <div className="flex flex-col justify-around mt-[10px]"><div className="text-[#332C5C] font-semibold text-[48px] leading-[130%]">20k</div><div className="text-[#5E587A] text-[18px] font-normal leading-[180%] nunita_font">Total Delivery</div></div>
                        </div>

                        <div className="flex  gap-3 lg:gap-8">
                            <div className="w-[128px] h-[128px] flex_jc_ac bg-[#ffbb0729] rounded-[40px]"><img src={Icon2}></img></div>
                            <div className="flex flex-col justify-around mt-[10px]"><div className="text-[#332C5C] font-semibold text-[48px] leading-[130%]">30k</div><div className="text-[#5E587A] text-[18px] font-normal leading-[180%] nunita_font">Total downloads</div></div>
                        </div>
                        <div className="flex  gap-3 lg:gap-8">
                            <div className="w-[128px] h-[128px] flex_jc_ac bg-[#6dedc31a] rounded-[40px]"><img src={Icon3}></img></div>
                            <div className="flex flex-col justify-around mt-[10px]"><div className="text-[#332C5C] font-semibold text-[48px] leading-[130%]">10k</div><div className="text-[#5E587A] text-[18px] font-normal leading-[180%] nunita_font">Customer</div></div>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
