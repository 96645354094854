import { useEffect } from "react";
import Header from "./Header";
import Footer from "./footer";
import ScrollToTop from "./scrollTop";

export default function Conditions() {
  const stylePara =
    "lg:w-full text-center lg:text-left default-text-color mt-[15px] text-[14px] leading-[34px] font-medium";
  const head =
    "title-text-color  text-center lg:text-left text-[20px] font-bold leading-[50px] mt-[10px]";
  useEffect(() => {}, []);
  return (
    <>
      <ScrollToTop />
      <div className="bg-[#fff]">
        {/* <div>
          <Header />
        </div> */}
        <div
          className="max-w-[100%] mx-auto pt-[180px] lg:px-16 px-16"
          id="terms"
        >
          <div>
            <main className="fs-main ">
              <h2 className={head}>Terms and Conditions</h2>
              <p className={stylePara}>Welcome to D-Man!</p>
              <p className={stylePara}>
                These terms and conditions mandates the rules and regulations on
                which the users are interested in browsing or availing D-Man
                services and accessing the platform https://dman.in/ and the
                mobile application that connects the merchants, delivery
                partners and the consumers to avail the D-Man services.
              </p>
              <p className={stylePara}>
                By accessing this website we assume you accept all the terms and
                conditions. Do not continue to use D-Man if you do not agree to
                take on all of the terms and conditions stated on this page.
              </p>
              <p className={stylePara}>
                The following terminology applies to these Terms and Conditions,
                Privacy Statement and Disclaimer Notice and all Agreements:
                "Client", "You" and "Your" refers to you, the person log on this
                website and compliant to the Company's terms and conditions.
                "The Company", "Ourselves", "We", "Our" and "Us", refers to our
                Company. "Party", "Parties", or "Us", refers to both the Client
                and ourselves. All terms refer to the offer, acceptance and
                consideration of payment necessary to undertake the process of
                our assistance to the Client in the most appropriate manner for
                the express purpose of meeting the Client's needs in respect of
                provision of the Company's stated services, in accordance with
                and subject to, prevailing law of Netherlands. Any use of the
                above terminology or other words in the singular, plural,
                capitalization and/or he/she or they, are taken as
                interchangeable and therefore as referring to same.
              </p>
              <h3 className={head}>Pricing</h3>
              <p className={stylePara}>
                Our pricings will differ based on the products that the
                customers choose while purchasing. As we offer different payment
                modes that includes cash on delivery (COD) and online payment.
                You understand that the Information, to the extent consisting of
                details relating to the manner and mode of payment of Charges
                for the use of our product (such as details of your bank
                account, credit card, debit card, net banking, etc.)
              </p>
              <h3 className={head}>Cancellation</h3>
              <p className={stylePara}>
                As a general rule you shall not be entitled to cancel your order
                once you have received confirmation of the same. If you cancel
                your order after it has been confirmed, D-Man shall have a right
                to charge you cancellation fee based on the order value.
              </p>
              <h3 className={head}>Cookies</h3>
              <p className={stylePara}>
                Like any other website, Our company uses data collection devices
                such as “cookies” on certain pages of the website to help
                analyse our web page flow, measure promotional effectiveness,
                and promote trust and safety. These cookies are used to store
                information including visitors' preferences, user credentials
                and the pages on the website that the visitor accessed or
                visited. The information is used to optimize the users'
                experience by customizing our web page content based on
                visitors' browser type and/or other information.
              </p>
              <h3 className={head}>License</h3>
              <p className={stylePara}>
                Unless otherwise stated, D-Man Private Limited and/or its
                licensors own the intellectual property rights for all material
                on D-Man. All intellectual property rights are reserved. You may
                access this from D-Man for your own personal use subjected to
                restrictions set in these terms and conditions.
              </p>
              <p className={stylePara}>You must not:</p>
              <ul>
                <li>Republish material from D-Man</li>
                <li>Sell, rent or sub-license material from D-Man</li>
                <li>Reproduce, duplicate or copy material from D-Man</li>
                <li>Redistribute content from D-Man</li>
              </ul>
              <p className={stylePara}>
                Parts of this website offer an opportunity for users to post and
                exchange opinions and information in certain areas of the
                website. D-Man Private Limited does not filter, edit, publish or
                review Comments prior to their presence on the website. Comments
                do not reflect the views and opinions of D-Man Private
                Limited,its agents and/or affiliates. Comments reflect the views
                and opinions of the person who post their views and opinions. To
                the extent permitted by applicable laws, D-Man Private Limited
                shall not be liable for the Comments or for any liability,
                damages or expenses caused and/or suffered as a result of any
                use of and/or posting of and/or appearance of the Comments on
                this website.
              </p>
              <p className={stylePara}>
                D-Man Private Limited reserves the right to monitor all Comments
                and to remove any Comments which can be considered
                inappropriate, offensive or causes breach of these Terms and
                Conditions.
              </p>
              <p className={stylePara}>You warrant and represent that:</p>
              <ul>
                <li>
                  You are entitled to post the Comments on our website and have
                  all necessary licenses and consents to do so;
                </li>
                <li>
                  The Comments do not invade any intellectual property right,
                  including without limitation copyright, patent or trademark of
                  any third party;
                </li>
                <li>
                  The Comments do not contain any defamatory, libelous,
                  offensive, indecent or otherwise unlawful material which is an
                  invasion of privacy
                </li>
                <li>
                  The Comments will not be used to solicit or promote business
                  or custom or present commercial activities or unlawful
                  activity.
                </li>
              </ul>
              <p className={stylePara}>
                You hereby grant D-Man Private Limited a non-exclusive license
                to use, reproduce, edit and authorize others to use, reproduce
                and edit any of your Comments in any and all forms, formats or
                media.
              </p>
              <h3 className={head}>Hyperlinking to our Content</h3>
              <p className={stylePara}>
                The following organizations may link to our Website without
                prior written approval:
              </p>
              <ul>
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>News organizations;</li>
                <li>
                  Online directory distributors may link to our Website in the
                  same manner as they hyperlink to the Websites of other listed
                  businesses; and
                </li>
                <li>
                  System wide Accredited Businesses except soliciting non-profit
                  organizations, charity shopping malls, and charity fundraising
                  groups which may not hyperlink to our Web site.
                </li>
              </ul>
              <p className={stylePara}>
                These organizations may link to our home page, to publications
                or to other Website information so long as the link: (a) is not
                in any way deceptive; (b) does not falsely imply sponsorship,
                endorsement or approval of the linking party and its products
                and/or services; and (c) fits within the context of the linking
                party's site.
              </p>
              <p className={stylePara}>
                We may consider and approve other link requests from the
                following types of organizations:
              </p>
              <ul>
                <li>
                  commonly-known consumer and/or business information sources;
                </li>
                <li>dot.com community sites;</li>
                <li>associations or other groups representing charities;</li>
                <li>online directory distributors;</li>
                <li>internet portals;</li>
                <li>accounting, law and consulting firms; and</li>
                <li>educational institutions and trade associations.</li>
              </ul>
              <p className={stylePara}>
                We will approve link requests from these organizations if we
                decide that: (a) the link would not make us look unfavorably to
                ourselves or to our accredited businesses; (b) the organization
                does not have any negative records with us; (c) the benefit to
                us from the visibility of the hyperlink compensates the absence
                of D-Man Private Limited; and (d) the link is in the context of
                general resource information.
              </p>
              <p className={stylePara}>
                These organizations may link to our home page so long as the
                link: (a) is not in any way deceptive; (b) does not falsely
                imply sponsorship, endorsement or approval of the linking party
                and its products or services; and (c) fits within the context of
                the linking party's site.
              </p>
              <p className={stylePara}>
                If you are one of the organizations listed in paragraph 2 above
                and are interested in linking to our website, you must inform us
                by sending an e-mail to D-Man Private Limited. Please include
                your name, your organization name, contact information as well
                as the URL of your site, a list of any URLs from which you
                intend to link to our Website, and a list of the URLs on our
                site to which you would like to link. Wait 2-3 weeks for a
                response.
              </p>
              <p className={stylePara}>
                Approved organizations may hyperlink to our Website as follows:
              </p>
              <ul>
                <li>By use of our corporate name; or</li>
                <li>
                  By use of the uniform resource locator being linked to; or
                </li>
                <li>
                  By use of any other description of our Website being linked to
                  that makes sense within the context and format of content on
                  the linking party's site.
                </li>
              </ul>
              <p className={stylePara}>
                No use of D-Man Private Limited's logo or its corresponding
                artwork will be allowed for linking absent a trademark license
                agreement.
              </p>
              <h3 className={head}>iFrames</h3>
              <p className={stylePara}>
                Without prior approval and written permission, you may not
                create frames around our Webpages that alter in any way the
                visual presentation or appearance of our Website.
              </p>
              <h3 className={head}>Content Liability</h3>
              <p className={stylePara}>
                We shall not hold responsible for any content that appears on
                your Website. You agree to protect and defend us against all the
                claims that are rising on your website. No link(s) should appear
                on any website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates
                the infringement or other violation of, any third party rights.
              </p>
              <h3 className={head}>Your Privacy</h3>
              <p className={stylePara}>
                All information is saved and stored on servers, which are
                secured with passwords and pins to ensure no unauthorised person
                has access to it. Once your information is in our possession we
                adhere to strict security guidelines, protecting it against
                unauthorized access. Company shall take reasonable steps to help
                protect your rights of privacy and your information (personal or
                otherwise) in an effort to prevent loss, misuse, unauthorized
                access, disclosure, alteration, or destruction of such
                information, in compliance with the applicable laws.
              </p>
              <h3 className={head}>Reservation of Rights</h3>
              <p className={stylePara}>
                We reserve the right to request that you remove all links or any
                particular link to our website.After the approval the following
                links will be removed. We also reserve the right to amend these
                terms and conditions and it's linking policy at any time. By
                continuously linking to our website, you agree to be bound to
                and follow these linking terms and conditions.
              </p>
              <h3 className={head}>Removal of links from our website</h3>
              <p className={stylePara}>
                If you find any link on our Website that is offensive for any
                reason, you are free to contact and inform us any moment. We
                will consider requests to remove links but we are not obligated
                to or so or to respond to you directly.
              </p>
              <p className={stylePara}>
                We do not ensure that the information on this website is
                correct, we do not warrant its completeness or accuracy; nor do
                we promise to ensure that the website remains available or that
                the material on the website is kept up to date.
              </p>
              <h3 className={head}>Disclaimer</h3>
              <p className={stylePara}>
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will:
              </p>
              <ul>
                <li>
                  limit or exclude our or your liability for death or personal
                  injury;
                </li>
                <li>
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation;
                </li>
                <li>
                  limit any of our or your liabilities in any way that is not
                  permitted under applicable law; or
                </li>
                <li>
                  exclude any of our or your liabilities that may not be
                  excluded under applicable law.
                </li>
              </ul>
              <p className={stylePara}>
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer, including liabilities arising in contract,
                in tort and for breach of statutory duty.
              </p>
              <p className={stylePara}>
                As long as the website, the information and services on the
                website are provided free of charge, we will not be liable for
                any loss or damage of any nature.
              </p>
            </main>
            <br />
            <br />
          </div>
        </div>
        {/* <div>
          <Footer />
        </div> */}
      </div>
    </>
  );
}
