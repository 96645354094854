import Overview from "./components/overview";
import Download from "./components/download";
import { Route, Routes, Navigate, HashRouter } from "react-router-dom";
import Privacy from "./components/privacy";
import Conditions from "./components/Conditions";
import Main from "./components/main";

function App() {

  return (
    <>
      <HashRouter>
        <Routes>
          <Route exact path="/download" element={<Download />} />
          <Route exact path="" element={<Main />} >
            <Route exact path="" element={<Navigate to="/home" />} />
            <Route exact path="/privacy-policy" element={<Privacy />} />
            <Route exact path="/terms" element={<Conditions />} />
            <Route path="*" element={<Overview />} />
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
