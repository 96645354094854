import { useEffect, useState } from "react";
import Image1 from "../assets/images/howitworks.png";
// import CurveBackground from "../assets/images/Ellipse.svg"
let transTimeout;
clearTimeout(transTimeout);
export default function HowitWorks() {
  const [count, setCount] = useState(2);
  useEffect( () => {
    // transTimeout = setTimeout(() => {
    //   if (count < 3) {
    //     setCount(count + 1);
    //   } else {
    //     setCount(1);
    //   }
    // }, 3000);
    transTimeout = setTimeout(() => {
      if (count < 2) {
        setCount(count + 1);
      } else {
        setCount(1);
      }
    }, 10000);
  },[count]);
  useEffect(() => {}, [count]);
  return (
    <div className="background3">
      <div className="max-w-[100%] mx-auto  pt-24 pb-20 relative z-10 bg-curve-set">
        <div>
          <div className="text-[56px] leading-[56px] font-bold text-center">
            How it{" "}
            <span className="cover_font theme-text-color text-[70px] leading-[70px] font-normal">
              works?
            </span>
          </div>
          <div className="flex flex-col lg:flex-row justify-between items-center mt-[100px]">
            <div className="p-10 lg:pl-24">
              <div className="relative  box-wrapper flex flex-col gap-10 ">
                {/* <div className="point">
                                <div className="title_trans flex items-center"><div className="slide_num_title mr-[79px]">01</div> <div className="slide_title">call</div></div>
                                <div className={`${count === 1 && "cont_trans"}  flex p1 gap-[84px] max-w-[500px] flex text-[#404040] text-[20px] leading-[32px]`}>
                                    <div className="w-[2px] bg-[#fff] h-[96px] mx-3 "><div className="w-full h-[60%] bg-[#2E2F35]"></div></div>
                                    <div className="slide_content">One-off announcements or automatically triggered flows. Customers receive their personalized cards within a week</div>
                                </div>
                            </div>
                            <div className="point">
                                <div className="title_trans flex items-center"><div className="slide_num_title mr-[62px]">02</div> <div className="slide_title">order</div></div>
                                <div className={`${count === 2 && "cont_trans"}  flex gap-[84px] p1 max-w-[500px] flex text-[#404040] text-[20px] leading-[32px]`}>
                                    <div className="w-[2px] bg-[#fff] h-[96px] mx-3"><div className="w-full h-[60%] bg-[#2E2F35]"></div></div>
                                    <div className="slide_content">One-off announcements or automatically triggered flows. Customers receive their personalized cards within a week</div>
                                </div>

                            </div>
                            <div className="point ">
                                <div className="title_trans flex items-center"><div className="slide_num_title mr-[65px]">03</div> <div className="slide_title">enjoy</div></div>
                                <div className={`${count === 3 && "cont_trans"}  flex gap-[84px] p1 max-w-[500px] flex text-[#404040] text-[20px] leading-[32px]`}>
                                    <div className="w-[2px] bg-[#fff] h-[96px] mx-3"><div className="w-full h-[60%] bg-[#2E2F35]"></div></div>
                                    <div className="slide_content">One-off announcements or automatically triggered flows. Customers receive their personalized cards within a week</div>
                                </div>
                            </div> */}
                <div className="point">
                  <div className="title_trans flex items-center">
                    <div className="slide_num_title mr-[79px]">01</div>{" "}
                    <div className="slide_title">Call - Order - Enjoy</div>
                  </div>
                  <div
                    className={`${
                      count === 1 && "cont_trans"
                    }  flex p1 gap-[84px] max-w-[500px] flex text-[#404040] text-[20px] leading-[32px]`}
                  >
                    <div className="w-[2px] bg-[#fff] h-[96px] mx-3 ">
                      <div className="w-full h-[60%] bg-[#2E2F35]"></div>
                    </div>
                    <div className="slide_content">
                      <p className="mb-[20px]">
                        "Craving Food? D Man Has You Covered!" 🍴🚀
                      </p>
                      <div className="mb-[20px]">
                        <p>👉 Open the D Man App</p>
                        <p>🥘 Order from any Sivaganga hotel</p>
                        <p>
                          ⏳ Get it delivered to your doorstep in just 25
                          minutes!
                        </p>
                      </div>
                      <p> Fast. Fresh. Convenient. 🚪✨</p>
                    </div>
                  </div>
                </div>
                <div className="point">
                  <div className="title_trans flex items-center">
                    <div className="slide_num_title mr-[62px]">02</div>{" "}
                    <div className="slide_title">Download app</div>
                  </div>
                  <div
                    className={`${
                      count === 2 && "cont_trans"
                    }  flex gap-[84px] p1 max-w-[500px] flex text-[#404040] text-[20px] leading-[32px]`}
                  >
                    <div className="w-[2px] bg-[#fff] h-[96px] mx-3">
                      <div className="w-full h-[60%] bg-[#2E2F35]"></div>
                    </div>
                    <div className="slide_content">
                      <p className="mb-[20px]">
                        "Enjoy Delicious Moments with D Man!" 🍴❤️
                      </p>
                      <div className="mb-[20px]">
                        <p>
                          🍽️ Bring home the taste of your favorite dishes and
                          enjoy quality time with your family.
                        </p>
                        <p>
                          🛵 Fast, reliable, and served with trust and care.
                        </p>
                      </div>
                      <p>
                        Because at D Man, it’s not just food, it’s a promise
                        delivered.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto">
              <img src={Image1}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
