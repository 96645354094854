import suresh from "../assets/images/suresh.svg";
import selva from "../assets/images/selva.svg";
import kumar from "../assets/images/kumar.svg";
import pandian from "../assets/images/pandian.svg";
import santhosh from "../assets/images/santhosh.svg";

import In from "../assets/images/in.svg";
import LinkedIn from "../assets/images/linkedin.svg";
import ScrollToTop from "./scrollTop";
let teamList = [
  {
    img: suresh,
    name: "Suresh Kumar",
    job: "Founder & CEO",
    linked: "https://www.linkedin.com/in/suresh-kumar-c-1b3940122",
  },
  {
    img: selva,
    name: "Kaleeswaran Selvaraj",
    job: "Operations Manager",
    linked: "https://www.linkedin.com/in/kaleeswaran-selvaraj-45bb19341",
  },
  {
    img: kumar,
    name: "Kishore Kumar",
    job: "Customer Relationship Manager",
    linked: "https://www.linkedin.com/in/kishore-kumar-710b25341",
  },
];
let teamList1 = [
  {
    img: pandian,
    name: "Shanmuga Pandian",
    job: "Delivery Fleet Manager",
    linked: "https://www.linkedin.com/in/shanmuga-pandian-430364273",
  },
  {
    img: santhosh,
    name: "Santhosh Kumar",
    job: "Supply Chain - Team Lead",
    linked:
      "https://www.linkedin.com/in/santhosh-kumar-s-santhosh-kumar-s-925522342",
  },
];

const Teams = () => {
  return (
    <>
    
    {/* <ScrollToTop /> */}
    <div>
      <div className="bg-[#fff] teams-bg">
        <div className="max-w-[100%] mx-auto pt-24 pb-15 px-10 lg:px-16">
          <div className="nav-text-color  text-[46px] lg:text-[56px] leading-[46px] lg:leading-[56px] font-bold text-center mb-10">
            Our{" "}
            <span className="theme-text-color cover_font text-[56px] leading-[56px] font-normal">
              Team
            </span>{" "}
            of D-man
          </div>
          <div className="flex gap-10 lg:gap-16 min-h-[300px] items-center flex-col lg:justify-center lg:flex-row lg:items-end flex-wrap  mb-[74px]">
            {teamList.map((member) => (
              <div className="team-card ">
                <div className="p-[10px] ease-in duration-300">
                  <div className="h-[160px]">
                    <img
                      src={member.img}
                      className="w-inherit h-inherit object-cover main "
                    />
                    <a href={member.linked} target="_blank">
                      <img
                        src={In}
                        className="w-inherit h-inherit object-cover linked ease-in duration-300"
                      />
                    </a>
                  </div>
                </div>
                <div className="relative min-w-[100%] lg:min-w-[336px] max-w-[336px] mt-[80px] flex flex-col justify-end items-center p-6 ">
                  <div className="flex items-center gap-5">
                    <div className="text-[#0D2159] text-[20px] leading-[20px] font-semibold">
                      {member.name ?? ""}
                    </div>
                  </div>

                  <div className="text-[#6D84A6] text-[16px] leading-[16px] mt-[10px]">
                    {member.job ?? ""}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pb-[128px] flex lg:justify-center gap-10 lg:gap-16 min-h-[300px] flex-col items-center lg:flex-row  lg:items-end flex-wrap">
            {teamList1.map((member) => (
              <div className="team-card">
                <div className="p-[10px]">
                  <div className=" h-[160px]">
                    <img
                      src={member.img}
                      className="w-inherit h-inherit object-cover main "
                    />
                    <a href={member.linked} target="_blank">
                      <img
                        src={In}
                        className="w-inherit h-inherit object-cover linked ease-in duration-300"
                      />
                    </a>
                  </div>
                </div>
                <div className="relative min-w-[100%] lg:min-w-[336px] max-w-[336px] mt-[80px] flex flex-col justify-end items-center p-6 ">
                  <div className="flex items-center gap-5">
                    <div className="text-[#0D2159] text-[20px] leading-[20px] font-semibold">
                      {member.name ?? ""}
                    </div>
                  </div>

                  <div className="text-[#6D84A6] text-[16px] leading-[16px] mt-[10px]">
                    {member.job ?? ""}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
    </div>
    </>
    
  );
};
export default Teams;
